<template>
    <front-layout>
        <v-container fluid class="page__top-line">
            <v-row>
                <v-col
                    cols="12"
                    md="7"
                    lg="8"
                    class="pl-8 pl-lg-16 pr-8 pb-9 fanPrimary--bg white--text"
                >
                    <h1 class="main__head-1 mb-4">Fan Connection</h1>
                    <div class="main__text-intro pl-sm-8 mb-8">
                        <p>Find some sweet new tunes by local artists.</p>
                        <p>All of the greatest bands started local. Be the first to know who’s hot and on stage in your area by joining GrubTunes!</p>
                        <p>GrubTunes is a platform that gives you the inside scoop on what’s happening weeknights and weekends in your town.</p>
                        <p>If you love great food and great music, this is the place to be.</p>
                    </div>
                </v-col>
                <v-col
                    cols="12"
                    md="5"
                    lg="4"
                    class="main__advantages veryDarkBlue white--text pa-10"
                >
                    <div class="mx-auto" style="width: fit-content;">
                        <h2 class="main__head-2 mb-10">Sign Up Now for Free!</h2>
                        <ul class="mb-16">
                            <li
                                v-for="(advantage,i) in advantages"
                                :key="`advantage-${i}`"
                            >
                                {{ advantage }}
                            </li>
                        </ul>
                        <v-btn
                            :disabled="$auth.user()"
                            :to="{name: 'sign-up', query: { role: 'fan' }, hash: '#signupForm'}"
                            block
                            x-large
                            class="white veryDarkBlue--text rounded-lg py-8"
                        >
                            Sign Up Now!
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="pl-8 pl-lg-16 pr-8 pb-9 fanPrimary white--text">
                    <div class="main__text-intro pl-sm-8 my-8">
                        <p>WITH YOUR FREE ACCOUNT:</p>
                        <p>Set up your profile and search for events, using these handy filters:</p>
                        <ul>
                            <li>Day/time</li>
                            <li>City, state</li>
                            <li>A specific artist</li>
                            <li>Genre of music</li>
                            <li>Food type (seafood, American, pizza, Mexican, etc.)</li>
                            <li>Venue features (waterfront, pet-friendly, etc.)</li>
                        </ul>
                        <p>Can “favorite” artists and venues and get updates as new shows are scheduled</p>
                        <p>Get push notifications of events you’ve added</p>
                        <p>Rate artists</p>
                        <p>Rate venues</p>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <div v-if="!featuredLoading">
            <v-container>
                <v-row class="main__plans darkGrayishBlue--text pt-6 justify-center">
                    <v-col
                        cols="12"
                        sm="6"
                        lg="4"
                        class="pb-16 d-flex flex-column"
                        v-for="(feature,i) in featured"
                        :key="`feature-${i}`"
                    >
                        <h4 class="mb-4 flex-grow-0">{{ feature.title }}</h4>
                        <v-img
                            :src="feature.imgSrc"
                            aspect-ratio="1"
                            class="mb-10 flex-grow-0"
                            :contain="feature.imgContain"
                        />
                        <p class="main__plans-name mb-8 flex-grow-0">{{ feature.name }}</p>
                        <v-btn
                            :to="feature.link"
                            :href="feature.href"
                            tile
                            block
                            x-large
                            class="darkGrayishBlue white--text mb-8 mt-auto flex-grow-0"
                        >
                            {{ feature.btnText }}
                        </v-btn>
                        <v-btn
                            :disabled="$auth.user()"
                            :to="{name: 'sign-up', query: { role: 'fan'}, hash: '#signupForm'}"
                            tile
                            block
                            x-large
                            class="strongRed white--text flex-grow-0"
                        >
                            Sign Up Now!
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </front-layout>
</template>

<script>
import FrontLayout from "@/layouts/FrontLayout";
import Artist from "@/models/Artist";
import Venue from "@/models/Venue";
import Gig from "@/models/Gig";

export default {
    name: "VenueLanding",
    components: {FrontLayout},
    data: function () {
        return {
            featuredLoading: true,
            advantages: [
                'Search local restaurants in your area',
                'Find hometown artists, then sample their music and follow them',
                'Get first alerts about shows happening near you',
                'Favorite all of the best spots to hang out and hear live music',
            ],
            featured: [],
        }
    },
    async mounted() {
        this.featuredLoading = true
        await this.fetchArtists()
        await this.fetchVenues()
        await this.fetchGigs()
        this.getFeaturedBlock()
        this.featuredLoading = false
    },
    methods: {
        async fetchArtists() {
            const artists = await Artist.custom('artists/public').orderBy('-id').limit(1).get()
            this.artist = artists[0]
            this.getFeaturedArtist()
            console.log(this.artist)
        },
        async fetchVenues() {
            const venues = await Venue.custom('venues/public').orderBy('-id').limit(1).get()
            this.venue = venues.data[0]
            this.getFeaturedVenue()
            console.log(this.venue)
        },
        async fetchGigs() {
            const events = await Gig.custom('gigs/public').orderBy('-id').limit(1).get()
            this.event = events[0]
            this.getFeaturedEvent()
            console.log(this.event)
        },
        getFeaturedArtist() {
            this.featuredArtist = {
                title: 'Featured Artist',
                btnText: 'See Artist',
                name: this.artist.name,
                link: `/artists/${this.artist.slug}`,
                imgSrc: this.artist.avatar_url,
                imgContain: true,
            }
        },
        getFeaturedVenue() {
            this.featuredVenue = {
                title: 'Featured Venue',
                btnText: 'See Venue',
                name: this.venue.name,
                link: `/venues/${this.venue.slug}`,
                address: this.venue.address,
                imgSrc: this.venue.featured_image,
            }
        },
        getFeaturedEvent() {
            this.featuredEvent = {
                title: 'Featured Event',
                btnText: 'See Event',
                name: this.event.title,
                href: this.event.livestream_url,
                imgSrc: this.event.avatar_url || 'https://cdn.vuetifyjs.com/images/cards/sunshine.jpg',
            }
        },
        getFeaturedBlock() {
            this.featured = [
                this.featuredArtist,
                this.featuredVenue,
                this.featuredEvent,
            ]
        }
    },
}
</script>

<style scoped>
</style>
